import * as pageTypes from "./pageTypes";
import * as appTypes from "../app/appTypes";
import {pageResources} from "../../data/axios";
import get from "lodash/get";

const state = {
    totalPages: null,
    unprocessedPages: null,
    processedPages: null,
    errorPages: null,
    pageToEdit: {
        url: "",
        title: "",
        description: "",
        image: null,
        tags: null,
        weight: 1
    },
    customPages: [],
    customPagesFilter: "all",
    query: null,
    pagination: {
        size: 10,
        page: 1,
        total: 0
    },
    ordenation: {
        sort: "processdate",
        order: "desc"
    }
};

const getters = {
    [pageTypes.GETTER_TOTAL_PAGES]: (state) => {
        return state.totalPages;
    },
    [pageTypes.GETTER_PROCESSED_PAGES]: (state) => {
        return state.processedPages;
    },
    [pageTypes.GETTER_UNPROCESSED_PAGES]: (state) => {
        return state.unprocessedPages;
    },
    [pageTypes.GETTER_ERROR_PAGES]: (state) => {
        return state.errorPages;
    },
    [pageTypes.GETTER_PAGE_TO_EDIT]: (state) => {
        return state.pageToEdit;
    },
    [pageTypes.GETTER_CUSTOM_PAGES]: (state) => {
        return state.customPages;
    },
    [pageTypes.GETTER_CUSTOM_PAGES_FILTER]: (state) => {
        return state.customPagesFilter;
    },
    [pageTypes.GETTER_CUSTOM_PAGES_QUERY]: (state) => {
        return state.query;
    },
    [pageTypes.GETTER_CUSTOM_PAGES_PAGINATION]: (state) => {
        return state.pagination;
    },
    [pageTypes.GETTER_CUSTOM_PAGES_ORDENATION]: (state) => {
        return state.ordenation;
    }
};

const mutations = {
    [pageTypes.MUTATE_SET_TOTAL_PAGES]: (state, payload) => {
        state.totalPages = payload;
    },
    [pageTypes.MUTATE_SET_PROCESSED_PAGES]: (state, payload) => {
        state.processedPages = payload;
    },
    [pageTypes.MUTATE_SET_UNPROCESSED_PAGES]: (state, payload) => {
        state.unprocessedPages = payload;
    },
    [pageTypes.MUTATE_SET_ERROR_PAGES]: (state, payload) => {
        state.errorPages = payload;
    },
    [pageTypes.MUTATE_SET_CUSTOM_PAGES]: (state, payload) => {
        state.customPages = payload;
    },
    [pageTypes.MUTATE_SET_CUSTOM_PAGES_FILTER]: (state, payload) => {
        state.customPagesFilter = payload;
    },
    [pageTypes.MUTATE_SET_CUSTOM_PAGES_QUERY]: (state, payload) => {
        state.query = payload;
    },
    [pageTypes.MUTATE_SET_CUSTOM_PAGES_PAGINATION]: (state, payload) => {
        state.pagination = payload;
    },
    [pageTypes.MUTATE_SET_CUSTOM_PAGES_ORDENATION]: (state, payload) => {
        state.ordenation = payload;
    },
    [pageTypes.MUTATE_SET_PAGE_TO_EDIT]: (state, payload) => {
        if (payload) {
            state.pageToEdit = payload;
        } else {
            state.pageToEdit = {
                url: "",
                title: "",
                description: "",
                image: null,
                tags: null,
                weight: 1
            };
        }
    }
};

const actions = {
    [pageTypes.ACTION_GET_PAGES_STATUS]: ({commit, dispatch}) => {
        pageResources.getStatus()
            .then((res) => {
                commit(pageTypes.MUTATE_SET_TOTAL_PAGES, res.data.totalPages);
                commit(pageTypes.MUTATE_SET_PROCESSED_PAGES, res.data.totalProcessed);
                commit(pageTypes.MUTATE_SET_UNPROCESSED_PAGES, res.data.totalUnprocessed);
                commit(pageTypes.MUTATE_SET_ERROR_PAGES, res.data.totalError);
            })
            .catch((err) => {
            
            });
    },
    
    [pageTypes.ACTION_GET_CUSTOM_PAGES]: ({commit}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        
        const q = state.query;
        
        return pageResources.getCustomPages({
            origin: "CUSTOM",
            q: state.customPagesFilter === "all" && q ? q : null,
            url: state.customPagesFilter === "url" && q ? q : null,
            tag: state.customPagesFilter === "tags" && q ? q : null,
            offset: state.pagination.size * (state.pagination.page - 1),
            size: state.pagination.size,
            ...state.ordenation
        })
            .then((res) => {
                commit(pageTypes.MUTATE_SET_CUSTOM_PAGES, res.data.pages);
                commit(pageTypes.MUTATE_SET_CUSTOM_PAGES_PAGINATION, {
                    size: state.pagination.size,
                    page: state.pagination.page,
                    total: get(res, "data.total[0].count", 0),
                    totalPages: Math.ceil(get(res, "data.total[0].count", 0) / state.pagination.size)
                });
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
    },
    
    [pageTypes.ACTION_POST_CUSTOM_PAGE]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        commit(appTypes.MUTATE_SET_LOADING);
        return pageResources.postPages({
            pages: [{
                url: payload.url,
                title: payload.title,
                description: payload.description,
                image: payload.image !== "" ? payload.image : null,
                tags: payload.tags,
                weight: payload.weight,
            }]
        })
            .then((res) => {
                commit(pageTypes.MUTATE_SET_PAGE_TO_EDIT, {
                    url: "",
                    title: "",
                    description: "",
                    image: null,
                    tags: null,
                    weight: 1
                });
            })
            .then(() => {
                dispatch(pageTypes.ACTION_GET_CUSTOM_PAGES);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            })
            .finally(() => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
            });
    },
    
    [pageTypes.ACTION_PUT_CUSTOM_PAGE]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        commit(appTypes.MUTATE_SET_LOADING);
        return pageResources.putPages({
            _id: payload._id,
            url: payload.url,
            title: payload.title,
            description: payload.description,
            image: payload.image !== "" ? payload.image : null,
            tags: payload.tags,
            weight: payload.weight,
        })
            .then((res) => {
                commit(pageTypes.MUTATE_SET_PAGE_TO_EDIT, {
                    url: "",
                    title: "",
                    description: "",
                    image: null,
                    tags: null,
                    weight: 1
                });
            })
            .then(() => {
                if (payload.getPages)
                    dispatch(pageTypes.ACTION_GET_CUSTOM_PAGES);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            })
            .finally(() => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
            });
    },
    
    [pageTypes.ACTION_DELETE_CUSTOM_PAGE]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        commit(appTypes.MUTATE_SET_LOADING);
        return pageResources.deletePage({deleteList: [payload]})
            .then(() => {
                commit(pageTypes.MUTATE_SET_PAGE_TO_EDIT, {
                    url: "",
                    title: "",
                    description: "",
                    image: null,
                    tags: null,
                    weight: 1
                });
            })
            .then(() => {
                dispatch(pageTypes.ACTION_GET_CUSTOM_PAGES);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            })
            .finally(() => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
            });
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
