export const stringFilters = {
    toCurrency(amount, currency) {
        if (!currency) currency = "brl";
        const value = (amount / 100);
        
        if (currency === "brl") return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
        }).format(value);
        
        else if (currency === "usd") return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(value);
        
        else return null;
    }
};
