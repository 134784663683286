<template>
</template>

<script>
    import * as paymentTypes from "../store/payment/paymentTypes";
    import {mapActions} from "vuex";
    
    export default {
        data() {
            return {};
        },
        methods: {
            ...mapActions({
                checkoutPlan: paymentTypes.ACTION_CHECKOUT_PLAN,
            })
        },
        created() {
            this.checkoutPlan(this.$route.query.planId);
        }
    };
</script>



