<template>
    <div :class="['main-navbar', 'bg-white', stickyTop ? 'sticky-top' : '']">
        <d-navbar class="align-items-stretch flex-md-nowrap p-0" type="light">
            <navbar-toggle @toggle="handleNavBarToggle"/>
            <!--      <navbar-search />-->
            <d-nav-item v-if="showDeveloperBtns">
                <button @click="$root.$i18n.locale === 'en' ? $root.$i18n.locale = 'pt' : $root.$i18n.locale = 'en'"
                        class="btn btn-info btn-sm">
                    Locale
                </button>
            </d-nav-item>
            <d-nav-item v-if="showDeveloperBtns">
                <button @click="$store.dispatch('app/ACTION_SET_ERROR', {msg:'error.unhandled_msg'})"
                        class="btn btn-info btn-sm">
                    Error
                </button>
            </d-nav-item>

            <d-nav-item v-if="showDeveloperBtns">
                <button @click="$store.dispatch('app/ACTION_SET_INFO', {msg:'This is a Information modal'})"
                        class="btn btn-info btn-sm">
                    Info
                </button>
            </d-nav-item>

            <div class="d-none d-md-inline-flex" style="width: 100%"></div> <!-- Just a spacing div -->
            <navbar-nav/>
        </d-navbar>
    </div>
</template>

<script>
import NavbarSearch from "./NavbarSearch.vue";
import NavbarNav from "./NavbarNav.vue";
import NavbarToggle from "./NavbarToggle.vue";

export default {
    data() {
        return {
            showDeveloperBtns: process.env.VUE_APP_SHOW_BETA_CONTENT === "true"
        };
    },
    components: {
        NavbarSearch,
        NavbarNav,
        NavbarToggle,
    },
    methods: {
        handleNavBarToggle() {
            this.$emit('toggle');
        }
    },
    props: {
        /**
         * Whether the main navbar should be sticky, or not.
         */
        stickyTop: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['toggle']
};
</script>

<style lang="scss">
.main-navbar {
    .dropdown-menu {
        display: block;
    }
}
</style>
