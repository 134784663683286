import {createRouter, createWebHistory} from "vue-router";
import {store} from "./store";
import * as appTypes from "./store/app/appTypes";
import * as accountTypes from "./store/account/accountTypes";

//imediate load
import Signin from "./views/SigninView";
import Logout from "./views/LogoutView";
import OAuth from "./views/OAuthView";
import Signup from "./views/SignupView";
import Checkout from "./views/checkoutView";

//lazy load on demand
const PasswordChange = () => import(/* webpackChunkName: "sb-pass-change" */ "./views/PasswordChangeView.vue");
const PasswordRecovery = () => import(/* webpackChunkName: "sb-pass-recovery" */ "./views/PasswordRecoveryView.vue");
const Installation = () => import(/* webpackChunkName: "sb-install" */ "./views/InstallationView.vue");
const Design = () => import(/* webpackChunkName: "sb-design" */ "./views/DesignView.vue");
const Dashboard = () => import(/* webpackChunkName: "sb-dashboard" */ "./views/DashboardView.vue");
const Help = () => import(/* webpackChunkName: "sb-help" */ "./views/HelpView.vue");
const API = () => import(/* webpackChunkName: "sb-help" */ "./views/APIDocView.vue");
const Account = () => import(/* webpackChunkName: "sb-account" */ "./views/AccountView.vue");
const Settings = () => import(/* webpackChunkName: "sb-settings" */ "./views/SettingsView.vue");
const ForDevs = () => import(/* webpackChunkName: "sb-for-devs" */ "./views/ForDevsView.vue");
const CustomResults = () => import(/* webpackChunkName: "sb-custom-results" */ "./views/CustomResultsView.vue");

//Lazy Load template group
const UserProfileLite = () => import(/* webpackChunkName: "shard-template" */ "./views/UserProfileLite.vue");
const AddNewPost = () => import(/* webpackChunkName: "shard-template" */ "./views/AddNewPost.vue");
const Errors = () => import(/* webpackChunkName: "shard-template" */ "./views/Errors.vue");
const ComponentsOverview = () => import(/* webpackChunkName: "shard-template" */ "./views/ComponentsOverview.vue");
const Tables = () => import(/* webpackChunkName: "shard-template" */ "./views/Tables.vue");
const BlogPosts = () => import(/* webpackChunkName: "shard-template" */ "./views/BlogPosts.vue");

export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior() {
        return {left: 0, top: 0};
    },
    routes: [
        {
            path: "/",
            redirect: "/installation",
        },
        {
            path: "/signin",
            name: "signin",
            component: Signin,
            meta: {
                layout: "public"
            },
            beforeEnter: autoLogin
        },
        {
            path: "/signup",
            name: "signup",
            component: Signup,
            meta: {
                layout: "public"
            },
            beforeEnter: autoLogin
        },
        {
            path: "/oauth",
            name: "oauth",
            component: OAuth,
            meta: {
                layout: "public"
            },
            beforeEnter: autoLogin
        },
        {
            path: "/change-password",
            name: "change-password",
            component: PasswordChange,
            meta: {
                layout: "public"
            }
        },
        {
            path: "/recovery-password",
            name: "recovery-password",
            component: PasswordRecovery,
            meta: {
                layout: "public"
            }
        },
        {
            path: "/installation",
            name: "installation",
            component: Installation,
            beforeEnter: authGuard,
        },
        {
            path: "/design",
            name: "design",
            component: Design,
            beforeEnter: authGuard
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
            beforeEnter: authGuard
        },
        {
            path: "/help",
            name: "help",
            component: Help,
            beforeEnter: authGuard
        },
        {
            path: "/api",
            name: "api",
            component: API,
            beforeEnter: authGuard
        },
        {
            path: "/checkout",
            name: "checkout",
            component: Checkout,
            meta: {
                layout: "public"
            },
            beforeEnter: authGuard
        },
        {
            path: "/account",
            name: "account",
            component: Account,
            beforeEnter: authGuard
        },
        {
            path: "/user-profile-lite",
            name: "user-profile-lite",
            component: UserProfileLite,
            beforeEnter: authGuard
        },
        {
            path: "/add-new-post",
            name: "add-new-post",
            component: AddNewPost,
            beforeEnter: authGuard
        },
        {
            path: "/errors",
            name: "errors",
            component: Errors,
            beforeEnter: authGuard
        },
        {
            path: "/logout",
            name: "logout",
            component: Logout,
            beforeEnter: authGuard
        },
        {
            path: "/components-overview",
            name: "components-overview",
            component: ComponentsOverview,
            beforeEnter: authGuard
        },
        {
            path: "/tables",
            name: "tables",
            component: Tables,
            beforeEnter: authGuard
        },
        {
            path: "/blog-posts",
            name: "blog-posts",
            component: BlogPosts,
            beforeEnter: authGuard
        },
        {
            path: "/settings",
            name: "settings",
            component: Settings,
            beforeEnter: authGuard
        },
        {
            path: "/for-devs",
            name: "for-devs",
            component: ForDevs,
            beforeEnter: authGuard
        },
        {
            path: "/custom-results",
            name: "custom-results",
            component: CustomResults,
            beforeEnter: authGuard
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: "/errors",
        },
    ],
});

async function authGuard(to, from, next) {
    if (store.getters[accountTypes.GETTER_TOKEN]) {
        next();
    } else {
        await store.dispatch(appTypes.ACTION_SET_PREVIOUS_PATH, to.fullPath);
        await store.dispatch(accountTypes.ACTION_TRY_AUTO_LOGIN);
        if (store.getters[accountTypes.GETTER_TOKEN]) {
            next();
        } else next("/signin");
    }
}

async function autoLogin(to, from, next) {
    await store.dispatch(accountTypes.ACTION_TRY_AUTO_LOGIN);
    next();
}
