<template>
    <div class="loader" v-if="showModalLoader">
        <sb-infinite-progress/>
    </div>
</template>

<script>
import InfiniteProgressBar from "../../../components/shared/InfiniteProgressBar";
import {mapGetters} from "vuex";
import * as appTypes from "../../../store/app/appTypes";

export default {
    name: "LoaderModal",
    data() {
        return {};
    },
    components: {
        "sb-infinite-progress": InfiniteProgressBar
    },
    computed: {
        ...mapGetters({
            showModalLoader: appTypes.GETTER_LOADING
        })
    }
};
</script>

<style scoped>
.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .85);

    z-index: 9999999999;
}
</style>
