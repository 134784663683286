import * as analyticsTypes from "./analyticsTypes";
import * as appTypes from "../app/appTypes";
import {analyticsResources} from "../../data/axios";
import {getDateMinusDays} from "../../utils/dateUtils.js";

const state = {
    analytics: {},
    comparisonAnalytics: {}
};

const getters = {
    [analyticsTypes.GETTER_ANALYTICS]: (state) => {
        return state.analytics;
    },
    [analyticsTypes.GETTER_COMPARISON_ANALYTICS]: (state) => {
        return state.comparisonAnalytics;
    }
};

const mutations = {
    [analyticsTypes.MUTATE_SET_ANALYTICS]: (state, payload) => {
        state.analytics = payload;
    },
    [analyticsTypes.MUTATE_SET_COMPARISON_ANALYTICS]: (state, payload) => {
        state.comparisonAnalytics = payload;
    }
};

const actions = {
    [analyticsTypes.ACTION_GET_ANALYTICS]: ({commit}, payload) => {
        const selectedOption = payload.selectedOption;
        payload = {
            startDate: payload.startDate,
            endDate: payload.endDate,
            size: payload.size
        };
        commit(appTypes.MUTATE_SET_LOADING);
        analyticsResources.getAnalytics(payload)
            .then((res) => {
                commit(analyticsTypes.MUTATE_SET_ANALYTICS, res.data);
            })
            .then(() => {
                payload = {
                    startDate: new Date(getDateMinusDays(new Date(payload.startDate), selectedOption)),
                    endDate: new Date(getDateMinusDays(new Date(payload.endDate), selectedOption)),
                    size: payload.size
                };
                analyticsResources.getAnalytics(payload)
                    .then((res) => {
                        commit(analyticsTypes.MUTATE_SET_COMPARISON_ANALYTICS, res.data);
                    });
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: err});
            })
            .finally(() => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
            });
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
