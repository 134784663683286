import * as loaderTypes from "./loaderTypes";
import * as appTypes from "../app/appTypes";
import {loaderResources} from "../../data/axios";

const state = {
    loaderScript: ""
};

const getters = {
    [loaderTypes.GETTER_LOADER_SCRIPT]: (state) => {
        return state.loaderScript;
    }
};

const mutations = {
    [loaderTypes.MUTATE_SET_LOADER_SCRIPT]: (state, payload) => {
        state.loaderScript = payload;
    }
};

const actions = {
    [loaderTypes.ACTION_GET_LOADER_SCRIPT]: ({commit, dispatch}) => {
        return loaderResources.getLoader()
            .then((res) => {
                commit(loaderTypes.MUTATE_SET_LOADER_SCRIPT, res.data);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: err});
            });
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
