const ClickOutsideDirective  = {
    beforeMount: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value(event);
            }
        };
        
        //register click event after while, to avoid to call this event on the open click event
        setTimeout(()=>{
            document.body.addEventListener('click', el.clickOutsideEvent);
        },20);
    },
    unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};

export default ClickOutsideDirective;
