<template>
    <div class="plan-select">
        <d-row align-content="center" class="mx-2 mt-3">
            <d-col>
                <h4 class="text-center font-weight-bold pb-3">{{ $t(props.title) }}</h4>
            </d-col>
        </d-row>
        <d-row class="mb-4">
            <d-button-group class="mr-2 d-block mx-auto mode-switch">
                <d-button
                    :theme="yearViewMode? 'secondary' : 'primary'"
                    class="text-uppercase mr-1 mode-btn"
                    pill
                    @click="yearViewMode = false">
                    {{ $t("views.plan_select.monthly_label") }}
                </d-button>
                <d-button
                    :theme="yearViewMode? 'primary' : 'secondary'"
                    class="text-uppercase mode-btn"
                    pill
                    @click="yearViewMode = true">
                    {{ $t("views.plan_select.yearly_label") }} {{ $t("views.plan_select.yearly_discount_label") }}
                </d-button>
            </d-button-group>
        </d-row>
        <d-row class="justify-content-center mx-4">
            <sb-plan-card v-for="(plan, index) in getFilteredPlans" :key="plan.id" :featured="index === 1"
                          :plan="plan"/>
        </d-row>
        <d-row class="justify-content-center mt-3">
            <h5 class="text-center close-btn" @click="exit"><u>{{ $t("views.plan_select.close_label") }}</u></h5>
        </d-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import * as paymentTypes from "../store/payment/paymentTypes";
import PlanCard from "../components/account/PlanCard";

export default {
    name: "plan-select-view",
    props: ["props"],
    components: {
        sbPlanCard: PlanCard
    },
    data() {
        return {
            yearViewMode: false
        };
    },
    methods: {
        save() {

        },
        exit() {
            this.$emit("close");
        }
    },
    computed: {
        ...mapGetters({
            getPlans: paymentTypes.GETTER_PLANS_WITH_PROPERTY
        }),
        ...mapActions({
            loadPlans: paymentTypes.ACTION_GET_PLANS
        }),
        getFilteredPlans() {
            return this.getPlans()
                .filter((plan) => {
                    const properties = plan.metadata.properties;
                    const haveProp = !!(!this.props.filterPlansProp || properties[this.props.filterPlansProp]);
                    return plan.interval === (this.yearViewMode ? "year" : "month") && haveProp;
                })
                .sort((planA, planB) => {
                    return planA.amount - planB.amount;
                });
        }
    },
    async mounted() {
        await this.loadPlans;
    }
};
</script>

<style lang="scss" scoped>

.plan-select {
    .close-btn {
        & :hover {
            cursor: pointer;
        }
    }

    .mode-switch {
        .mode-btn {
            height: 44px;
            width: 170px;
            font-weight: 500;

            & :focus {
                box-shadow: unset;
                background-color: unset;
                background: unset;
            }
        }
    }
}
</style>


