<template>
    <d-navbar-nav class="border-left flex-row">
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-nowrap px-3" v-d-toggle.user-actions v-if="getAccount">
                <img :src="getAvatar" alt="User Avatar" class="user-avatar rounded-circle mr-2"> <span
                class="d-none d-md-inline-block">{{ getAccount.info.name }}</span>
            </a>
            <d-collapse class="dropdown-menu dropdown-menu-small" id="user-actions">
                <d-dropdown-item>
                    <router-link to="/account" class="text-decoration-none text-reset">
                        <i class="material-icons">&#xE7FD;</i>{{ $t("views.account.title") }}
                    </router-link>
                </d-dropdown-item>
                <d-dropdown-divider/>
                <d-dropdown-item class="text-danger" href="#">
                    <router-link to="/logout" class="text-decoration-none text-reset">
                        <i class="material-icons text-danger">&#xE879;</i> {{ $t("views.logout.tittle") }}
                    </router-link>
                </d-dropdown-item>
            </d-collapse>
        </li>
    </d-navbar-nav>
</template>

<script>
import {mapGetters} from "vuex";
import * as accountTypes from "../../../store/account/accountTypes";

export default {
    name: "nav-bar-nav",
    computed: {
        ...mapGetters({
            getAvatar: accountTypes.GETTER_AVATAR,
            getAccount: accountTypes.GETTER_ACCOUNT
        })
    }
};
</script>


<style>
.nav-link:hover {
    cursor: pointer;
}
</style>
