/* eslint-disable */
import {createApp, h} from "vue";
import ShardsVue from "shards-vue3";
// import ShardsVue from "shards-vue3/dist/shards-vue3.esm.min";

import "./scss/_overrides.scss";

// Lib Styles
import "bootstrap/scss/bootstrap.scss";
import "highlight.js/styles/github.css";

//Project Styles
import "./scss/default.scss";
import "./assets/scss/date-range.scss";

// Core
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import {store} from "./store";
import {createDeviceDetector} from "next-vue-device-detector";

// Layouts
import AuthenticatedLayout from "./layouts/AuthenticatedLayout.vue";
import PublicLayout from "./layouts/PublicLayout";
import AddResultModal from "./components/custom-results/AddResultModal";

// Filters
import {stringFilters} from "./helpers/filters/stringFilters";

const app = createApp({
    render() {
        return h(App);
    },
    beforeCreate() {
        if (process.env.NODE_ENV === "production") {
            appendGTAG();
        }
    }
});

//Components Plugin
ShardsVue.install(app);

//Custom Components
app.component("authenticated-layout", AuthenticatedLayout);
app.component("public-layout", PublicLayout);
app.component("add-result-modal", AddResultModal);
app.use(createDeviceDetector());

//Custom globals
app.config.globalProperties.$eventHub = createApp({});
app.config.globalProperties.$filters = {stringFilters};

//Plugins
app.use(router);
app.use(store);
app.use(i18n);

app.mount("#app");

//Gtag Script
function appendGTAG() {
    let gtagScript = document.createElement("script");
    gtagScript.innerHTML = "" +
        "<!-- Google Tag Manager -->\n" +
        "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
        "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
        "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        "})(window,document,'script','dataLayer','GTM-5TZTLBG');\n" +
        "<!-- End Google Tag Manager -->";
    
    document.head.appendChild(gtagScript);
}
