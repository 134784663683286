export const getDateMinusDays = (date, days) => {
  // returns the given date, minus the informed quantity of days
  return date.setDate(date.getDate() - days);
};

export const getDiferenceInDaysOfTwoDates = (data1, data2) => {
  // returns the nuber of days between two dates 
  data1 = new Date(data1);
  data2 = new Date(data2);
  if (data1.getTime() >= data2.getTime()) {
    return Math.round((data1.getTime() - data2.getTime()) / 86400000);
  }
  return Math.round((data2.getTime() - data1.getTime()) / 86400000);
};

export const getDateAtStartOfDay = date => {
  // returns the date at the first moment of the day
  return new Date(date).setHours(0,0,0,0);
};

export const getDateAtEndOfDay = date => {
  // returns the date at the last moment of the day
  return new Date(date).setHours(23,59,59,999);
};

export const areTwoDatesEqual = (date1, date2) => {
  // checks if two dates are on the same day, month and year
  date1 = new Date(date1);
  date2 = new Date(date2);

  return date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate();
};