import router from "../../router";
import * as accountTypes from "../../store/account/accountTypes.js";
import * as appTypes from "../../store/app/appTypes";
import * as loaderTypes from "../../store/loader/loaderTypes";
import {accountResources, paymentResources} from "../../data/axios";
import properties from "../../data/properties";
import PlanSelectView from "../../views/PlanSelectView";

const BILLING_CUSTOMER_ADMIN_URL = process.env.VUE_APP_BILLING_CUSTOMER_ADMIN_URL;

const state = {
    account: null,
    subscription: null,
    token: null,
    publicToken: null,
    sites: [],
    principalSite: null,
    avatar: require("../../assets/images/avatar.png"),
    settings: null
};

const getters = {
    [accountTypes.GETTER_ACCOUNT]: (state) => {
        return state.account;
    },
    [accountTypes.GETTER_SUBSCRIPTION]: (state) => {
        return state.subscription;
    },
    [accountTypes.GETTER_INFO]: (state) => {
        return state.account.info;
    },
    [accountTypes.GETTER_TOKEN]: (state) => {
        return state.token;
    },
    [accountTypes.GETTER_PUBLIC_TOKEN]: (state) => {
        return state.publicToken;
    },
    [accountTypes.GETTER_SITES]: (state) => {
        return state.sites;
    },
    [accountTypes.GETTER_PRINCIPAL_SITE]: (state) => {
        return state.principalSite;
    },
    [accountTypes.GETTER_AVATAR]: (state) => {
        return state.avatar;
    },
    [accountTypes.GETTER_TEST_URL]: (state) => {
        return properties.API_URL + "/demo/test/" + state.publicToken;
    },
    [accountTypes.GETTER_SETTINGS]: (state) => {
        return state.settings;
    },
    [accountTypes.GETTER_BILLING_CUSTOMER_ADMIN_URL]: (state) => {
        const billingUrl = new URLSearchParams({prefilled_email: state.account.user});
        return `${BILLING_CUSTOMER_ADMIN_URL}?${billingUrl.toString()}`;
    }
};

const mutations = {
    [accountTypes.MUTATE_SET_ACCOUNT]: (state, payload) => {
        state.account = payload;
    },
    [accountTypes.MUTATE_SET_SUBSCRIPTION]: (state, payload) => {
        state.subscription = payload;
    },
    [accountTypes.MUTATE_SET_INFO]: (state, payload) => {
        state.account.info = payload;
    },
    [accountTypes.MUTATE_SET_TOKEN]: (state, payload) => {
        state.token = payload;
    },
    [accountTypes.MUTATE_SET_PUBLIC_TOKEN]: (state, payload) => {
        state.publicToken = payload;
    },
    [accountTypes.MUTATE_CLEAR_AUTH_DATA]: (state) => {
        state.token = null;
        state.publicToken = null;
        state.account = null;
        state.subscription = null;
    },
    [accountTypes.MUTATE_SET_SITES]: (state, payload) => {
        state.sites = payload;
        if (state.sites.length > 0) {
            state.principalSite = state.sites[0].site;
        } else state.principalSite = null;
        
    },
    [accountTypes.MUTATE_SET_PRINCIPAL_SITE]: (state, payload) => {
        state.principalSite = payload;
    },
    [accountTypes.MUTATE_SET_SETTINGS]: (state, payload) => {
        state.settings = payload;
    }
};

const actions = {
    [accountTypes.ACTION_LOGIN]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        return accountResources.postLogin(payload.email, payload.password)
            .then((res) => {
                const authPromise = dispatch(accountTypes.ACTION_SET_AUTH_DATA, res.data);
                const accountPromise = dispatch(accountTypes.ACTION_GET_ACCOUNT);
                return Promise.all([authPromise, accountPromise]);
            }).catch((err) => {
                if (err.response) {
                    const res = err.response;
                    if (res.status === 401) {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.bad_credentials_msg"});
                    } else if (res.status === 403) {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.suspended_account_msg"});
                    } else {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err.message});
                    }
                } else {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err.message});
                }
            });
    },
    
    [accountTypes.ACTION_SIGNUP]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        commit(appTypes.MUTATE_SET_LOADING);
        return accountResources.postRegister(payload.email, payload.password)
            .then((res) => {
                dispatch(accountTypes.ACTION_LOGIN, payload)
                    .then(() => {
                        commit(appTypes.MUTATE_CLEAR_LOADING);
                    });
            }).catch((err) => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
                if (err.response) {
                    const res = err.response;
                    if (res.status === 422) {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.invalid_credentials_msg"});
                    } else if (res.status === 400) {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.existent_account_msg"});
                    }
                } else {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err.message});
                }
            });
    },
    
    [accountTypes.ACTION_GET_ACCOUNT]: ({commit, dispatch}) => {
        const accountPromise = accountResources.getAccount()
            .then((res) => {
                commit(accountTypes.MUTATE_SET_ACCOUNT, res.data);
                dispatch(accountTypes.ACTION_GET_SITES)
                    .then(() => {
                        dispatch(appTypes.ACTION_REDIRECT_TO_PREVIOUS_PATH);
                    });
                dispatch(appTypes.ACTION_UPDATE_JOBS, 500);
                dispatch(loaderTypes.ACTION_GET_LOADER_SCRIPT);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
        
        const subscriptionPromise = dispatch(accountTypes.ACTION_GET_SUBSCRIPTION);
        
        return Promise.all([accountPromise, subscriptionPromise]);
    },
    
    [accountTypes.ACTION_REFRESH_ACCOUNT]: ({commit, dispatch}) => {
        return accountResources.getAccount()
            .then((res) => {
                commit(accountTypes.MUTATE_SET_ACCOUNT, res.data);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
    },
    
    [accountTypes.ACTION_GET_SUBSCRIPTION]: async ({commit, dispatch}) => {
        commit(appTypes.MUTATE_SET_LOADING);
        return await paymentResources.getSubsciption()
            .then((res) => {
                let subscription = res.data;
                commit(accountTypes.MUTATE_SET_SUBSCRIPTION, subscription);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    commit(accountTypes.MUTATE_SET_SUBSCRIPTION, {
                        plan: {
                            nickname: "Free",
                            metadata: {
                                properties: {
                                    "pageLimit": 50,
                                    "indexingInterval": 10,
                                    "showAds": true,
                                    "restAPIAccess": false,
                                    "analytics": false,
                                    "styling": false,
                                    "multiDomain": false
                                }
                            },
                            isFree: true
                        }
                    });
                } else {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
                }
            })
            .finally(() => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
            });
    },
    
    [accountTypes.ACTION_SET_INFO]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_SET_LOADING);
        accountResources.putInfo(payload)
            .then((res) => {
                commit(appTypes.MUTATE_SET_INFO, {msg: "info.saved_success"});
                dispatch(accountTypes.ACTION_REFRESH_ACCOUNT)
                    .finally(() => {
                        commit(appTypes.MUTATE_CLEAR_LOADING);
                    });
            })
            .catch((err) => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
    },
    
    [accountTypes.ACTION_GET_SITES]: ({commit, dispatch}) => {
        
        return accountResources.getSites()
            .then((res) => {
                commit(accountTypes.MUTATE_SET_SITES, res.data);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
    },
    
    [accountTypes.ACTION_UPDATE_SITE]: ({commit, dispatch}, site) => {
        commit(appTypes.MUTATE_SET_LOADING);
        return accountResources.putSite(site)
            .then((res) => {
                dispatch(appTypes.ACTION_UPDATE_JOBS, 500);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 400 || err.response.status === 422) {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.bad_site_url_msg"});
                    } else {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err.message});
                    }
                } else {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
                }
            })
            .finally(() => {
                dispatch(accountTypes.ACTION_GET_SITES)
                    .finally(() => commit(appTypes.MUTATE_CLEAR_LOADING));
            });
        
    },
    
    [accountTypes.ACTION_SET_TIMEOUT]: ({commit, dispatch}, expirationDate) => {
        const date = new Date(expirationDate);
        const now = new Date();
        
        let expirationTime = date.getTime() - now.getTime();
        if (expirationTime <= 0) expirationTime = 1000;
        
        setTimeout(() => {
            commit(accountTypes.MUTATE_CLEAR_AUTH_DATA);
            router.push("/signin");
        }, expirationTime);
    },
    
    [accountTypes.ACTION_SET_AUTH_DATA]: ({commit, dispatch}, authData) => {
        commit(accountTypes.MUTATE_SET_TOKEN, authData.token);
        commit(accountTypes.MUTATE_SET_PUBLIC_TOKEN, authData.publicToken);
        
        if (authData.previousPath) commit(appTypes.MUTATE_SET_PREVIOUS_PATH, authData.previousPath);
        
        localStorage.setItem("token", authData.token);
        localStorage.setItem("publicToken", authData.publicToken);
        localStorage.setItem("expirationDate", authData.expirationDate);
        localStorage.setItem("user", authData.user);
        
        const expires = new Date(authData.expirationDate);
        const domain = process.env.VUE_APP_DOMAIN;
        
        document.cookie = "appLoggedUser=" + authData.user + "; expires=" + expires + "; path=/; domain=" + domain;
        
        dispatch(accountTypes.ACTION_SET_TIMEOUT, authData.expirationDate);
        
    },
    
    [accountTypes.ACTION_TRY_AUTO_LOGIN]: async ({commit, dispatch}) => {
        const token = localStorage.getItem("token");
        if (!token) {
            return;
        }
        const publicToken = localStorage.getItem("publicToken");
        if (!publicToken) {
            return;
        }
        
        const user = localStorage.getItem("user");
        if (!user) {
            return;
        }
        
        const expirationDate = new Date(localStorage.getItem("expirationDate"));
        const now = new Date();
        if (now >= expirationDate) {
            return;
        }
        
        await dispatch(accountTypes.ACTION_SET_AUTH_DATA, {
            token,
            publicToken,
            expirationDate,
            user
        });
        
        await dispatch(accountTypes.ACTION_GET_ACCOUNT);
    },
    
    [accountTypes.ACTION_SEND_RECOVERY_EMAIL]: ({commit, dispatch}, email) => {
        commit(appTypes.MUTATE_SET_LOADING);
        let toEmail;
        if (state.account && state.account.user) {
            toEmail = state.account.user;
        }
        if (email) toEmail = email;                                 //if not logged, an email should be given
        accountResources.postRecovery(toEmail)
            .then((res) => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
                commit(appTypes.MUTATE_SET_INFO, {
                    msg: "info.recovery_email_success",
                    params: {email: toEmail}
                });
                if (email) dispatch(accountTypes.ACTION_LOGOUT);    //if not logged, go to login page
            })
            .catch((err) => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
                if (err.response && err.response.status === 404) {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.non_existent_account_msg"});
                } else {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err.message});
                }
            });
    },
    
    [accountTypes.ACTION_LOGOUT]: ({commit, dispatch}) => {
        commit(accountTypes.MUTATE_CLEAR_AUTH_DATA);
        localStorage.clear();
        
        const domain = process.env.VUE_APP_DOMAIN;
        
        document.cookie = "appLoggedUser=clear; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC" + "; domain=" + domain;
        router.push("/signin");
    },
    
    [accountTypes.ACTION_CHANGE_PASSWORD]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_SET_LOADING);
        return accountResources.putPassword(payload.password, payload.token)
            .then((res) => {
                const onCloseHandler = () => {
                    dispatch(accountTypes.ACTION_LOGOUT);
                };
                
                commit(appTypes.MUTATE_CLEAR_LOADING);
                commit(appTypes.MUTATE_SET_INFO, {msg: "info.password_change_success", onCloseHandler: onCloseHandler});
            })
            .catch((err) => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
                if (err.response) {
                    if (err.response.status === 401) {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.invalid_link_msg"});
                    } else {
                        commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err.message});
                    }
                } else {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.invalid_link_msg", details: err});
                }
            });
    },
    
    /**
     * @param commit
     * @param dispatch
     * @param options
     * @property {string} options.requiredProp
     * @property {function} options.cancelCallback run if user does nothing
     * @property {function} options.passCallback run if user subscription is enougth
     * @property {function} options.rejectCallback run if user subscription is not enougth
     * @property {function} options.subscriptionCallback
     */
    [accountTypes.ACTION_CHECK_USER_PLAN]: ({commit, dispatch, getters}, options) => {
        const newOptions = {
            title: "error.insuficient_plan_msg",
            requiredProp: options.requiredProp
        };
        
        if (state.subscription) {
            const properties = state.subscription.plan.metadata.properties;
            const haveProp = properties[options.requiredProp];
            if (haveProp) {
                options.passCallback();
            } else {
                if (options.rejectCallback) options.rejectCallback();
                dispatch(accountTypes.ACTION_OPEN_PLAN_SELECTOR, newOptions);
            }
        } else {
            dispatch(accountTypes.ACTION_OPEN_PLAN_SELECTOR, newOptions);
        }
    },
    
    
    /**
     * @param commit
     * @param dispatch
     * @param options
     * @property {string} options.requiredProp
     * @property {function} options.title
     */
    [accountTypes.ACTION_OPEN_PLAN_SELECTOR]: ({commit, dispatch}, options) => {
        const props = {
            title: options.title,
            filterPlansProp: options.requiredProp
        };
        
        commit(appTypes.MUTATE_SET_CONTENT_MODAL, {
            content: PlanSelectView,
            props: props,
            show: true
        });
    },
    
    [accountTypes.ACTION_GET_SETTINGS]: ({commit, dispatch}) => {
        return accountResources.getSettings()
            .then((res) => {
                commit(accountTypes.MUTATE_SET_SETTINGS, res.data);
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
    },
    
    [accountTypes.ACTION_UPDATE_SETTINGS]: ({commit, dispatch}, payload) => {
        commit(appTypes.MUTATE_CLEAR_ERROR);
        commit(appTypes.MUTATE_SET_LOADING);
        return accountResources.putSettings(state.settings)
            .then((res) => {
                commit(appTypes.MUTATE_SET_INFO,
                    {
                        msg: "info.saved_success",
                        onCloseHandler: () => {
                            if (payload && payload.closeModalHandler) {
                                payload.closeModalHandler();
                            }
                        }
                    });
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            })
            .finally(() => {
                commit(appTypes.MUTATE_CLEAR_LOADING);
            });
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
