<template>
    <d-col align-self="center" class="plan-card py-1 mx-0" md="4" sm="12">
        <d-card :class="{'featured':featured}" class="m-auto text-center py-4">
            <h5 class="title text-primary font-weight-bold my-0 md">{{ plan.nickname }}</h5>
            <p class="price font-weight-bold my-0">
                {{  $filters.stringFilters.toCurrency(plan.amount, plan.currency) }}
            </p>
            <p class="interval">
                {{
                    plan.interval === "month" ?
                        $t("views.plan_select.monthly_label") : $t("views.plan_select.yearly_label")
                }}
            </p>
            <div class="features">
                <p v-for="feature in plan.metadata.features" :key="feature" class="feature m-0">{{ feature }}</p>
            </div>
            <hr class="pt-0 mt-0">
            <d-button
                :disabled="plan.disabled"
                class="d-block m-auto choose"
                pill size="lg" theme="primary"
                @click="checkout(plan.id)">
                {{ $t("views.plan_select.choose_label") }}
            </d-button>
        </d-card>
    </d-col>
</template>

<script>
import {mapActions} from "vuex";
import * as paymentTypes from "../../store/payment/paymentTypes";

export default {
    name: "PlanCard",
    props: {
        plan: Object,
        featured: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        current: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions({
            checkoutPlan: paymentTypes.ACTION_CHECKOUT_PLAN
        }),
        checkout(planId) {
            this.checkoutPlan(planId);
        }

    }
};
</script>

<style lang="scss" scoped>
@import "../../scss/_overrides";

.plan-card {
    padding-left: 0;
    padding-right: 0;
    min-width: 260px;

    .title {
        text-transform: uppercase;
    }

    .price {
        font-size: 2rem;
    }

    .features {
        height: 175px;

        .feature {
            font-size: .75rem;
            border-top: 1px solid $border-color;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .choose {
        max-width: 150px;
        font-size: 1rem;
        padding: 0.7rem 1.5rem;

    }

    .featured {
        border: 3px solid $primary;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}
</style>
