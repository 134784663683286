<template>
</template>

<script>
    import * as accountTypes from "../store/account/accountTypes";
    import {mapActions} from "vuex";
    
    export default {
        data() {
            return {};
        },
        methods: {
            ...mapActions({
                logoutAction: accountTypes.ACTION_LOGOUT
            })
        },
        created() {
            this.logoutAction();
        }
    };
</script>



