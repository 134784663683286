const prefix = 'account/';

// Getters
export const GETTER_ACCOUNT = prefix + 'GETTER_ACCOUNT';
export const GETTER_SUBSCRIPTION = prefix + 'GETTER_SUBSCRIPTION';
export const GETTER_INFO = prefix + 'GETTER_INFO';
export const ACTION_GET_SITES = prefix + 'ACTION_GET_SITES';
export const GETTER_TOKEN = prefix + 'GETTER_TOKEN';
export const GETTER_PUBLIC_TOKEN = prefix + 'GETTER_PUBLIC_TOKEN';
export const GETTER_SITES = prefix + 'GETTER_SITES';
export const GETTER_PRINCIPAL_SITE = prefix + 'GETTER_PRINCIPAL_SITE';
export const GETTER_AVATAR = prefix + 'GETTER_AVATAR';
export const GETTER_TEST_URL = prefix + 'GETTER_TEST_URL';
export const GETTER_SETTINGS = prefix + 'GETTER_SETTINGS';
export const GETTER_BILLING_CUSTOMER_ADMIN_URL = prefix + 'GETTER_BILLING_CUSTOMER_ADMIN_URL';

// Mutations
export const MUTATE_SET_ACCOUNT = prefix + 'MUTATE_SET_ACCOUNT';
export const MUTATE_SET_SUBSCRIPTION = prefix + 'MUTATE_SET_SUBSCRIPTION';
export const MUTATE_SET_INFO = prefix + 'MUTATE_SET_INFO';
export const MUTATE_SET_TOKEN = prefix + 'MUTATE_SET_TOKEN';
export const MUTATE_SET_PUBLIC_TOKEN = prefix + 'MUTATE_SET_PUBLIC_TOKEN';
export const MUTATE_CLEAR_AUTH_DATA = prefix + 'MUTATE_CLEAR_AUTH_DATA';
export const MUTATE_SET_SITES = prefix + 'MUTATE_SET_SITES';
export const MUTATE_SET_PRINCIPAL_SITE = prefix + 'MUTATE_SET_PRINCIPAL_SITE';
export const MUTATE_SET_SETTINGS = prefix + 'MUTATE_SET_SETTINGS';

// Actions
export const ACTION_LOGIN = prefix + 'ACTION_LOGIN';
export const ACTION_SIGNUP = prefix + 'ACTION_SUGNUP';
export const ACTION_LOGOUT = prefix + 'ACTION_LOGOUT';
export const ACTION_GET_ACCOUNT = prefix + 'ACTION_GET_ACCOUNT';
export const ACTION_REFRESH_ACCOUNT = prefix + 'ACTION_REFRESH_ACCOUNT';
export const ACTION_GET_SUBSCRIPTION = prefix + 'ACTION_GET_SUBSCRIPTION';
export const ACTION_SET_INFO = prefix + 'ACTION_SET_INFO';
export const ACTION_SET_TIMEOUT = prefix + 'ACTION_SET_TIMEOUT';
export const ACTION_SET_AUTH_DATA = prefix + 'ACTION_SET_AUTH_DATA';
export const ACTION_CHANGE_PASSWORD = prefix + 'ACTION_CHANGE_PASSWORD';
export const ACTION_TRY_AUTO_LOGIN = prefix + 'ACTION_TRY_AUTO_LOGIN';
export const ACTION_SEND_RECOVERY_EMAIL = prefix + 'ACTION_SEND_RECOVERY_EMAIL';
export const ACTION_UPDATE_SITE = prefix + 'ACTION_SET_SITES';
export const ACTION_CHECK_USER_PLAN = prefix + 'ACTION_CHECK_USER_PLAN';
export const ACTION_OPEN_PLAN_SELECTOR = prefix + 'ACTION_OPEN_PLAN_SELECTOR';
export const ACTION_GET_SETTINGS = prefix + 'ACTION_GET_SETTINGS';
export const ACTION_UPDATE_SETTINGS = prefix + 'ACTION_UPDATE_SETTINGS';
