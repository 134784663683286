export const formValidationMixin = {
    methods: {
        validateEmail(email) {
            if (!email) return false;
            const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return mailformat.test(email);
        },
        validatePassword(password) {
            if (!password) return false;
            const passFormat = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;  //
            return passFormat.test(password);
        },
        validateName(name) {if (!name) return false;
            return name.length >= 3 && name.length <= 40;
        }
    }
};
