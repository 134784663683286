const prefix = 'payment/';

// Getters
export const GETTER_PLANS = prefix + 'GETTER_PLANS';
export const GETTER_PLANS_WITH_PROPERTY = prefix + 'GETTER_PLANS_WITH_PROPERTY';

// Mutations
export const MUTATE_SET_PLANS = prefix + 'MUTATE_SET_PLANS';

// Actions
export const ACTION_GET_PLANS = prefix + 'ACTION_GET_PLANS';
export const ACTION_CHECKOUT_PLAN = prefix + 'ACTION_CHECKOUT_PLAN';

