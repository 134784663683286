import * as appTypes from "../../store/app/appTypes";
import * as paymentTypes from "../../store/payment/paymentTypes";
import {paymentResources} from "../../data/axios";

const state = {
    plans: null
};

const getters = {
    [paymentTypes.GETTER_PLANS]: (state) => {
        return state.plans;
    },
    [paymentTypes.GETTER_PLANS_WITH_PROPERTY]: (state) => (property) => {
        if (property && state.plans) {
            return state.plans.filter((plan) => {
                return plan.metadata.property[property] === true;
            });
        } else return state.plans || [];
    }
};

const mutations = {
    [paymentTypes.MUTATE_SET_PLANS]: (state, payload) => {
        state.plans = payload;
    },
};

const actions = {
    async [paymentTypes.ACTION_GET_PLANS]({commit, getters}) {
        if (!getters[paymentTypes.GETTER_PLANS]) {
            commit(appTypes.MUTATE_SET_LOADING);
            await paymentResources.getPlans(getters[appTypes.GETTER_LANGUAGE])
                .then((res) => {
                    if (res.data.length > 0) {
                        let parsedPlans = res.data.map((plan) => {
                            plan.metadata.properties = JSON.parse(plan.metadata.properties);
                            plan.metadata.features = JSON.parse(plan.metadata.features);
                            return plan;
                        });
                        commit(paymentTypes.MUTATE_SET_PLANS, parsedPlans);
                    } else commit(paymentTypes.MUTATE_SET_PLANS, null);
                })
                .catch((err) => {
                    commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
                });
            commit(appTypes.MUTATE_CLEAR_LOADING);
        }
    },
    [paymentTypes.ACTION_CHECKOUT_PLAN]({commit, getters}, planId) {
        paymentResources.getSession(planId)
            .then((res) => {
                const sessionID = res.data.id;
                window.location = process.env.VUE_APP_API_URL + "/payment/checkout?sessionId=" + sessionID;
            })
            .catch((err) => {
                commit(appTypes.MUTATE_SET_ERROR, {msg: "error.unhandled_msg", details: err});
            });
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
