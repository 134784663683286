<template>
    <div>
        <sb-error-modal/>
        <sb-loader-modal/>
        <sb-info-modal/>
        <sb-content-modal/>
        <slot></slot>
    </div>
</template>

<script>
import ErrorModal from "../../components/shared/modals/ErrorModal";
import LoaderModal from "../../components/shared/modals/LoaderModal";
import InfoModal from "../../components/shared/modals/InfoModal";
import ContentModal from "../../components/shared/modals/ContentModal";

export default {
    name: "modalHOC",
    components: {
        "sb-error-modal": ErrorModal,
        'sb-loader-modal': LoaderModal,
        'sb-info-modal': InfoModal,
        'sb-content-modal': ContentModal
    }
};
</script>

<style scoped>

</style>
