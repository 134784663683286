export default function () {
    const showBetaFeatures = process.env.VUE_APP_SHOW_BETA_CONTENT === "true";
    
    return [
        {
            titleI18nKey: "views.dashboard.title",
            to: {
                name: "dashboard",
            },
            htmlBefore: "<i class=\"material-icons\">insert_chart</i>",
            htmlAfter: "",
            show: true
        },
        {
            titleI18nKey: "views.install.title",
            to: {
                name: "installation",
            },
            htmlBefore: "<i class=\"material-icons\">build</i>",
            htmlAfter: "",
            show: true
            
        },
        {
            titleI18nKey: "views.design.title",
            to: {
                name: "design",
            },
            htmlBefore: "<i class=\"material-icons\">brush</i>",
            htmlAfter: "",
            show: true
        },
        {
            titleI18nKey: "views.custom_results.custom_results",
            to: {
                name: "custom-results",
            },
            htmlBefore: "<i class=\"material-icons\">sell</i>",
            htmlAfter: "",
            show: true
        },
        {
            titleI18nKey: "views.for_devs.menu",
            to: {
                name: "for-devs",
            },
            htmlBefore: "<i class=\"material-icons\">code</i>",
            htmlAfter: "",
            show: true
        },
        {
            titleI18nKey: "views.settings.title",
            to: {
                name: "settings",
            },
            htmlBefore: "<i class=\"material-icons\">settings</i>",
            htmlAfter: "",
            show: true
        },
        {
            titleI18nKey: "views.api.title",
            htmlBefore: "<i class=\"material-icons\">integration_instructions</i>",
            to: {
                name: "api",
            },
            show: true
        },
        {
            titleI18nKey: "views.help.title",
            htmlBefore: "<i class=\"material-icons\">help</i>",
            to: {
                name: "help",
            },
            show: true
        },
        {
            titleI18nKey: "Blog Posts",
            htmlBefore: "<i class=\"material-icons\">vertical_split</i>",
            to: {
                name: "blog-posts",
            },
            show: showBetaFeatures
        },
        {
            titleI18nKey: "Add New Post",
            htmlBefore: "<i class=\"material-icons\">note_add</i>",
            to: {
                name: "add-new-post",
            },
            show: showBetaFeatures
        },
        {
            titleI18nKey: "Forms & Components",
            htmlBefore: "<i class=\"material-icons\">view_module</i>",
            to: {
                name: "components-overview",
            },
            show: showBetaFeatures
        },
        {
            titleI18nKey: "Tables",
            htmlBefore: "<i class=\"material-icons\">table_chart</i>",
            to: {
                name: "tables",
            },
            show: showBetaFeatures
        },
        {
            titleI18nKey: "User Profile",
            htmlBefore: "<i class=\"material-icons\">person</i>",
            to: {
                name: "user-profile-lite",
            },
            show: showBetaFeatures
        },
        {
            titleI18nKey: "Errors",
            htmlBefore: "<i class=\"material-icons\">error</i>",
            to: {
                name: "errors",
            },
            show: showBetaFeatures
        }];
}
