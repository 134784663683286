<template>
	<d-container fluid>
		<d-row>
				<!-- Content -->
				<slot/>
		</d-row>
	</d-container>
</template>

<script>
</script>

