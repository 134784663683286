const prefix = 'app/';

// Getters
export const GETTER_LOADING = prefix + 'GETTER_LOADING';
export const GETTER_ERROR = prefix + 'GETTER_ERROR';
export const GETTER_INFO = prefix + 'GETTER_INFO';
export const GETTER_SHOW_MODAL_ERROR = prefix + 'GETTER_SHOW_MODAL_ERROR';
export const GETTER_SHOW_MODAL_INFO = prefix + 'GETTER_SHOW_MODAL_INFO';
export const GETTER_CONTENT_MODAL = prefix + 'GETTER_CONTENT_MODAL';
export const GETTER_PREVIOUS_PATH = prefix + "GETTER_PREVIOUS_PATH";
export const GETTER_JOBS = prefix + "GETTER_JOB";
export const GETTER_LANGUAGE = prefix + "GETTER_LANGUAGE";

// Mutations
export const MUTATE_SET_LOADING = prefix + 'MUTATE_SET_LOADING';
export const MUTATE_CLEAR_LOADING = prefix + 'MUTATE_CLEAR_LOADING';
export const MUTATE_SET_ERROR = prefix + 'MUTATE_SET_ERROR';
export const MUTATE_SET_INFO = prefix + 'MUTATE_SET_INFO';
export const MUTATE_CLEAR_ERROR = prefix + 'MUTATE_CLEAR_ERROR';
export const MUTATE_CLEAR_INFO = prefix + 'MUTATE_CLEAR_INFO';
export const MUTATE_SET_PREVIOUS_PATH = prefix + 'MUTATE_SET_PREVIOUS_PATH';
export const MUTATE_CLEAR_PREVIOUS_PATH = prefix + 'MUTATE_CLEAR_PREVIOUS_PATH';
export const MUTATE_SET_JOBS = prefix + 'MUTATE_SET_JOB';
export const MUTATE_CLEAR_JOBS = prefix + 'MUTATE_CLEAR_JOB';
export const MUTATE_SET_CONTENT_MODAL = prefix + 'MUTATE_SET_CONTENT_MODAL';
export const MUTATE_CLEAR_CONTENT_MODAL = prefix + 'MUTATE_CLEAR_CONTENT_MODAL';
export const MUTATE_SET_LANGUAGE = prefix + 'MUTATE_SET_LANGUAGE';

// Actions
export const ACTION_CLEAR_ERROR = prefix + 'ACTION_CLEAR_ERROR';
export const ACTION_CLEAR_INFO = prefix + 'ACTION_CLEAR_INFO';
export const ACTION_CLEAR_CONTENT_MODAL = prefix + 'ACTION_CLEAR_CONTENT_MODAL';
export const ACTION_SET_ERROR = prefix + 'ACTION_SET_ERROR';
export const ACTION_SET_INFO = prefix + 'ACTION_SET_INFO';
export const ACTION_SET_PREVIOUS_PATH = prefix + 'ACTION_SET_PREVIOUS_PATH';
export const ACTION_REDIRECT_TO_PREVIOUS_PATH = prefix + 'ACTION_REDIRECT_TO_PREVIOUS_PATH';
export const ACTION_UPDATE_JOBS = prefix + 'ACTION_UPDATE_JOB';
export const ACTION_SET_LANGUAGE = prefix + 'ACTION_SET_LANGUAGE';
