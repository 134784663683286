const prefix = 'analytics/';

// Getters
export const GETTER_ANALYTICS = prefix + 'GETTER_ANALYTICS';
export const GETTER_COMPARISON_ANALYTICS = prefix + 'GETTER_COMPARISON_ANALYTICS';

// Mutations
export const MUTATE_SET_ANALYTICS = prefix + 'MUTATE_SET_ANALYTICS';
export const MUTATE_SET_COMPARISON_ANALYTICS = prefix + 'MUTATE_SET_COMPARISON_ANALYTICS';

// Actions
export const ACTION_GET_ANALYTICS = prefix + 'ACTION_GET_ANALYTICS';