<template>
  <div :class="`d-flex wrapper ${isFocused ? 'wrapper-active' : ''}`" @click="focusInput">
    <sb-tag class="tag" v-for="tag, idx in tags" :key="idx" :text="tag" :onClickRemove="removeTagHandler"></sb-tag>
    <!-- Insere tag ao pressioanr enter, tab, esc, virgula ou ponto e virgula -->
    <input 
      ref="tag" 
      class="input"
      v-model="tagToAdd" 
      :placeholder="$t('actions.add_tag')"
      @focus="isFocused = true"
      @blur="handleBlur"
      @keydown.enter="addTag"
      @keydown.tab="addTag"
      @keydown.esc="addTag"
      @keydown.188="addTag"
      @keydown.191="addTag"
      @keydown.delete="handleBackspace"
    />
  </div>
</template>

<script>
import Tag from '../../components/shared/Tag';
export default {
  data() {
    return {
      tagToAdd: '',
      isFocused: false
    };
  },
  props: {
    tags: {
      type: Array
    },
    addTagHandler: {
      type: Function
    },
    removeTagHandler: {
      type: Function
    }
  },
  components: {
    sbTag: Tag
  },
  methods: {
    focusInput() {
      this.$refs.tag.focus();
    },
    handleBlur() {
      this.isFocused = false;
      this.addTagHandler(this.tagToAdd);
      this.tagToAdd = '';
    },
    addTag(e) {
      e.preventDefault();
      this.addTagHandler(this.tagToAdd);
      this.tagToAdd = '';
    },
    handleBackspace(e) {
      if (!this.tagToAdd.length > 0 && this.tags) {
        e.preventDefault();
        this.removeTagHandler(this.tags[this.tags.length - 1]);
      }
    }
  }
};
</script>

<style scoped>
.input {
  border: none;
  outline: none;
  color: #495057;
}
.wrapper {
  border: 1px solid #e1e5eb;
  padding: 0.4rem;
  border-radius: 0.25rem;
  cursor: text;
  flex-wrap: wrap;
}
.tag {
  margin: 0.15rem 0.25rem 0.15rem 0;
}
.wrapper-active {
  border-color: #076bff;
  box-shadow: 0 0.313rem 0.719rem rgb(7 107 255 / 10%), 0 0.156rem 0.125rem rgb(0 0 0 / 6%);
}
</style>
