<template>
</template>

<script>
    import * as accountTypes from "../store/account/accountTypes";
    import {mapActions} from "vuex";
    
    export default {
        data() {
            return {};
        },
        methods: {
            ...mapActions({
                loginAction: accountTypes.ACTION_SET_AUTH_DATA,
                getAccountAction: accountTypes.ACTION_GET_ACCOUNT,
            })
        },
        created() {
            this.loginAction(this.$route.query);
            this.getAccountAction();
        }
    };
</script>



