<template>
	<sb-modal-h-o-c>
		<component :is="layout">
			<router-view/>
		</component>
	</sb-modal-h-o-c>
</template>

<script>
    import modalHOC from "./components/hoc/modalHOC";
    
    export default {
        computed: {
            layout() {
                return `${this.$route.meta.layout || "authenticated"}-layout`;
            },
        },
        components: {
            sbModalHOC: modalHOC
        }
    };
</script>
