import router from "../../router";
import * as appTypes from "../../store/app/appTypes";
import * as pageTypes from "../../store/page/pageTypes";
import {jobResources} from "../../data/axios";
import * as accountTypes from "../../store/account/accountTypes";

const state = {
    loading: false,
    error: null,
    info: null,
    showModalError: false,
    showModalInfo: false,
    previousPath: null,
    runningJobs: null,
    contentModal: {
        content: null,
        props: null,
        show: false
    },
    language: "en"
};

const getters = {
    [appTypes.GETTER_LOADING]: (state) => {
        return state.loading;
    },
    [appTypes.GETTER_ERROR]: (state) => {
        return state.error;
    },
    [appTypes.GETTER_INFO]: (state) => {
        return state.info;
    },
    [appTypes.GETTER_SHOW_MODAL_ERROR]: (state) => {
        return state.showModalError;
    },
    [appTypes.GETTER_SHOW_MODAL_INFO]: (state) => {
        return state.showModalInfo;
    },
    [appTypes.GETTER_CONTENT_MODAL]: (state) => {
        return state.contentModal;
    },
    [appTypes.GETTER_PREVIOUS_PATH]: (state) => {
        return state.previousPath;
    },
    [appTypes.GETTER_JOBS]: (state) => {
        return state.runningJobs || [];
    },
    [appTypes.GETTER_LANGUAGE]: (state) => {
        return state.language;
    },
};

const mutations = {
    [appTypes.MUTATE_SET_LOADING]: (state) => {
        state.loading = true;
    },
    [appTypes.MUTATE_CLEAR_LOADING]: (state) => {
        state.loading = false;
    },
    [appTypes.MUTATE_SET_ERROR]: (state, payload) => {
        state.error = payload;
        state.showModalError = true;
    },
    [appTypes.MUTATE_CLEAR_ERROR]: (state) => {
        state.error = null;
        state.showModalError = false;
    },
    [appTypes.MUTATE_SET_INFO]: (state, payload) => {
        state.info = payload;
        state.showModalInfo = true;
    },
    [appTypes.MUTATE_CLEAR_INFO]: (state) => {
        state.info = null;
        state.showModalInfo = false;
    },
    [appTypes.MUTATE_SET_PREVIOUS_PATH]: (state, payload) => {
        state.previousPath = payload;
    },
    [appTypes.MUTATE_CLEAR_PREVIOUS_PATH]: (state) => {
        state.previousPath = null;
    },
    [appTypes.MUTATE_SET_JOBS]: (state, payload) => {
        state.runningJobs = payload;
    },
    [appTypes.MUTATE_CLEAR_JOBS]: (state) => {
        state.runningJobs = null;
    },
    [appTypes.MUTATE_SET_CONTENT_MODAL]: (state, payload) => {
        state.contentModal = payload;
    },
    [appTypes.MUTATE_CLEAR_CONTENT_MODAL]: (state, payload) => {
        state.contentModal = {
            content: null,
            props: null,
            show: false
        };
    },
    [appTypes.MUTATE_SET_LANGUAGE]: (state, payload) => {
        state.language = payload;
    }
};

const actions = {
    [appTypes.ACTION_CLEAR_ERROR]({commit}) {
        commit(appTypes.MUTATE_CLEAR_ERROR);
    },
    [appTypes.ACTION_SET_ERROR]({commit}, payload) {
        commit(appTypes.MUTATE_SET_ERROR, payload);
    },
    [appTypes.ACTION_CLEAR_INFO]({commit}) {
        commit(appTypes.MUTATE_CLEAR_INFO);
    },
    [appTypes.ACTION_SET_INFO]({commit}, payload) {
        commit(appTypes.MUTATE_SET_INFO, payload);
    },
    [appTypes.ACTION_CLEAR_CONTENT_MODAL]({commit}) {
        commit(appTypes.MUTATE_CLEAR_CONTENT_MODAL);
    },
    [appTypes.ACTION_SET_PREVIOUS_PATH]({commit}, payload) {
        commit(appTypes.MUTATE_SET_PREVIOUS_PATH, payload);
    },
    [appTypes.ACTION_REDIRECT_TO_PREVIOUS_PATH]({commit, getters}) {
        if (state.previousPath) {
            router.push(state.previousPath).catch((err) => {
            });
        } else {
            if (getters[accountTypes.GETTER_PRINCIPAL_SITE]) {
                router.push("/dashboard").catch((err) => {
                });
            }
            else {
                router.push("/").catch((err) => {
                });
            }
            
            
        }
        commit(appTypes.MUTATE_CLEAR_PREVIOUS_PATH);
    },
    
    [appTypes.ACTION_UPDATE_JOBS]: ({commit, dispatch}, timeout) => {
        if (!timeout) timeout = 5000;
        
        setTimeout(() => {
            jobResources.getRunningJobs()
                .then((res) => {
                    commit(appTypes.MUTATE_SET_JOBS, res.data);
                    dispatch(pageTypes.ACTION_GET_PAGES_STATUS);
                    if (res.data && res.data.length > 0) {
                        dispatch(appTypes.ACTION_UPDATE_JOBS);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        commit(appTypes.MUTATE_CLEAR_JOBS);
                    } else {
                        console.error(error);
                    }
                });
        }, timeout);
    },
    
    [appTypes.ACTION_SET_LANGUAGE]: ({commit, dispatch}, lang) => {
        commit(appTypes.MUTATE_SET_LANGUAGE, lang);
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};
