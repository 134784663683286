<template>
	<d-modal @close="clearError" class="modal" :show="showModalError">
		<d-modal-header>
			<d-modal-title>{{$t('error.title')}}</d-modal-title>
		</d-modal-header>
		<d-modal-body>
			{{$t('' + getError.msg, getError.params)}}
		</d-modal-body>
		<d-modal-footer v-if="getError.details">{{getError.details}}</d-modal-footer>
		<d-button ref="button" @click="clearError" class="d-block mx-auto mb-2 px-4" theme="primary">OK</d-button>
	</d-modal>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import * as appTypes from "../../../store/app/appTypes";
    
    export default {
        name: "ErrorModal",
        data() {
            return {};
        },
        methods: {
            ...mapActions({
                clearError: appTypes.ACTION_CLEAR_ERROR
            })
        },
        computed: {
            ...mapGetters({
                showModalError: appTypes.GETTER_SHOW_MODAL_ERROR,
                getError: appTypes.GETTER_ERROR,
            })
        }
    };
</script>

<style scoped>
	.modal {
		z-index: 9999;
	}
</style>
