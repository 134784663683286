<template>
  <d-form class="form" @submit="onSubmit">
    <strong>{{props.isEdit ? $t('views.custom_results.modal.edit_title') : $t('views.custom_results.modal.create_title')}}</strong>

    <div class="d-flex mt-4">
      <div style="width: 100%" class="mr-2 mb-0 form-group">
        <label for="url">{{$t('views.custom_results.modal.url')}}</label>
        <d-input
          id="url"
          type="url"
          :state="validateUrl"
          v-model="pageToEdit.url"
          :required="true"
        ></d-input>
        <d-form-invalid-feedback>{{$t('views.custom_results.modal.invalid_url')}}</d-form-invalid-feedback>
      </div>

      <div>
        <label for="weight">{{$t('views.custom_results.modal.weight')}}</label>
        <d-input
          id="weight"
          type="number"
          v-model="pageToEdit.weight"
          min="0"
          :state="validateWeight"
        ></d-input>
      </div>
    </div>

    <div class="d-flex align-items-end justify-content-between">
      <label for="title">{{$t('views.custom_results.modal.title')}}</label>
      <span class="counter">{{ titleCounter }}</span>
    </div>
    <d-input
      id="title"
      class="mr-1"
      :required="true"
      v-model="pageToEdit.title"
      :state="validateTitle"
    ></d-input>

    <div style="width: 100%" class="mb-0 form-group">
      <label for="image">{{$t('views.custom_results.modal.image')}}</label>
      <d-input
        id="image"
        type="url"
        v-model="pageToEdit.image"
        :state="validateImage"
      ></d-input>
      <d-form-invalid-feedback>{{$t('views.custom_results.modal.invalid_url')}}</d-form-invalid-feedback>
    </div>

    <div class="d-flex align-items-end justify-content-between">
      <label for="description">{{$t('views.custom_results.modal.description')}}</label>
      <span class="counter">{{ descriptionCounter }}</span>
    </div>
    <textarea
      id="description"
      :class="`form-control mr-1 ${validateDescription !== null ? validateDescription ? 'is-valid' : 'is-invalid' : ''}`"
      rows="4"
      v-model="pageToEdit.description"
      :state="validateDescription"
      :required="true"
    ></textarea>

    <label for="tags">{{$t('views.custom_results.modal.tags')}}</label>
    <input-tags
      id="tags"
      :tags="pageToEdit.tags" 
      :add-tag-handler="addTag"
      :remove-tag-handler="removeTag"
      ></input-tags>

    <div class="footer mt-4" :style="{justifyContent: props.isEdit ? 'space-between' : 'flex-end'}">
      <d-button v-if="props.isEdit" class="mr-2 red-button" type="button" @click="onDelete">{{$t('actions.delete')}}</d-button>
      <div class="d-flex">
        <d-button class="mr-2" theme="light" type="button" @click="onCancel">{{$t('actions.cancel')}}</d-button>
        <d-button type="submit" @submit="onSubmit">{{props.isEdit ? $t('actions.save') : $t('actions.create')}}</d-button>
      </div>
    </div>
  </d-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as pageTypes from "../../store/page/pageTypes";
import * as appTypes from "../../store/app/appTypes";
import InputTags from '../../components/inputs/InputTags';
import { isValidUrl } from "../../utils";
export default {
  props: {
    props: {
      type: Object,
      default: () => { return  {isEdit: false}; }
    }
  },
  components: {
    InputTags
  },
  methods: {
    ...mapMutations({ 
      setPageToEdit: pageTypes.MUTATE_SET_PAGE_TO_EDIT,
      setPagination: pageTypes.MUTATE_SET_CUSTOM_PAGES_PAGINATION,
      setOrdenation: pageTypes.MUTATE_SET_CUSTOM_PAGES_ORDENATION,
      setQuery: pageTypes.MUTATE_SET_CUSTOM_PAGES_QUERY,
      setContentModal: appTypes.MUTATE_SET_CONTENT_MODAL
    }),
    ...mapActions({
      createPage: pageTypes.ACTION_POST_CUSTOM_PAGE,
      editPage: pageTypes.ACTION_PUT_CUSTOM_PAGE,
      getPages: pageTypes.ACTION_GET_CUSTOM_PAGES,
      deletePage: pageTypes.ACTION_DELETE_CUSTOM_PAGE
    }),
    onDelete() {
      this.resetSearch();
      this.deletePage(this.pageToEdit.url);
      this.onCancel();
    },
    addTag(tag) {
      if (tag.trim()) {
        if (!this.pageToEdit.tags) {
          this.pageToEdit.tags = [];
        }
        if (!this.pageToEdit.tags.includes(tag)) {
          this.pageToEdit.tags.push(tag);
        }
      }
    },
    removeTag(tag) {
      this.setPageToEdit({
        ...this.pageToEdit, 
        tags: this.pageToEdit.tags && this.pageToEdit.tags.length > 1 ? this.pageToEdit.tags.filter(x =>  x !== tag) : null
      });
    },
    onSubmit(e) {
      e.preventDefault();
      if (
        this.validateUrl &&
        this.validateTitle &&
        this.validateDescription &&
        (!this.pageToEdit.image || this.validateImage) &&
        (!this.pageToEdit.weight || this.validateWeight)
      ) {
        this.resetSearch();
        if (this.props.isEdit) {
          this.editPage(this.pageToEdit);
        } else {
          this.createPage(this.pageToEdit);
        }
        this.getPages();
        this.onCancel();
      }
    },
    resetSearch() {
      this.setPagination({...this.pagination, page: 1});
      this.setOrdenation({sort: 'processdate', order: 'desc'});
      this.setQuery(null);
    },
    onCancel() {
      this.setPageToEdit(null);
      this.setContentModal({show: false, content: null});
      this.getPages();
    }
  },
  computed: {
    ...mapGetters({ 
      pageToEdit: pageTypes.GETTER_PAGE_TO_EDIT,
      pagination: pageTypes.GETTER_CUSTOM_PAGES_PAGINATION
    }),
    validateUrl() {
      return this.pageToEdit.url ? isValidUrl(this.pageToEdit.url) : null;
    },
    validateWeight() {
      const value = this.pageToEdit.weight;
      if (value) {
        if (value > 100 || value < 1) {
          return false;
        }
        return true;
      }
      return null;
    },
    validateTitle() {
      const value = this.pageToEdit.title;
      if (value) {
        if (value.length > 400) {
          return false;
        }
        return true;
      }
      return null;
    },
    validateImage() {
      return this.pageToEdit.image ? isValidUrl(this.pageToEdit.image) : null;
    },
    validateDescription() {
      const value = this.pageToEdit.description;
      if (value) {
            if (value.length > 4000) {
          return false;
        }
        return true;
      }
      return null;
    },
    titleCounter() {
      return `${this.pageToEdit.title.length}/400`;
    },
    descriptionCounter() {
      return `${this.pageToEdit.description.length}/1500`;
    },
  },
};
</script>

<style scoped>
strong {
  font-size: 20px;
}
i {
  font-size: 20px;
  color: #FFF;
}
label {
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}
.form {
  width: 600px;
  padding: 0 1rem 1rem 1rem;
}
#input {
  width: 100%;
}
#weight {
  width: 80px;
}
.footer {
  width: 100%;
  display: flex;
}
.counter {
  color: #5a6169;
  font-size: 13px;
}
.tags {
  width: 50%;
}
.botao {
  padding: 0.3rem 0.8rem;
}
.red-button {
  justify-self: flex-start;
  background: #EC6464;
  border-color: #FFF;
}
.red-button:hover {
  background: #e74949;
}
.red-button:active {
  background: #e74949;
}
@media screen and (max-width: 768px) {
  .form {
    width: 95%;
    padding: 0 0 1rem 1rem;
  }
  .tags {
    width: 100%;
  }
}
</style>
