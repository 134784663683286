const Properties = function () {
    let API_URL = process.env.VUE_APP_API_URL;
    
    return {
        SITE_URL: "https://www.searchbar.org",
        API_URL: API_URL,
        API_DOCUMENTATION_URL: "https://documenter.getpostman.com/view/3227776/TzCS4km9#intro",
        GOOGLE_OAUTH_URL: API_URL + "/account/auth/google",
        FACEBOOK_OAUTH_URL: API_URL + "/account/auth/facebook",
        INSTALLATION_GUIDE_URL: {
            "pt": "https://www.searchbar.org/pt-br/central-de-ajuda",
            "en": "https://www.searchbar.org/en-us/help-center"
        }
    };
};

export default Properties();