<template>
  <div class="tag-wrapper" :style="{background: background}" tabindex="0" @click="() => {}">
    <span style="white-space: nowrap; margin-right: 3px">{{text}}</span>
    <i v-if="onClickRemove" class="material-icons"  @mouseup="onClickRemove(text)" style="cursor: pointer">close</i>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    background: {
      type: String,
      default: '#f5f6f8'
    },
    onClickRemove: {
      type: Function,
      default: null
    }
  },
};
</script>

<style scoped>
.tag-wrapper {
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  border: 1px #b1aeae solid;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.2rem 0.4rem 0.25rem 0.4rem;
  font-size: 13px;
  cursor: default;
  transition: 1s ease-in-out all;
}
</style>