<template>
    <d-modal @close="onCloseHandler" class="modal" :show="showModalInfo">
        <d-modal-header>
            <d-modal-title>{{ $t("info.title") }}</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            {{ $t("" + getInfo.msg, getInfo.params) }}
        </d-modal-body>
        <d-modal-footer v-if="getInfo.details">{{ getInfo.details }}</d-modal-footer>
        <d-button @click="onCloseHandler" class="d-block mx-auto mb-2 px-4" ref="button" theme="primary">OK</d-button>
    </d-modal>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import * as appTypes from "../../../store/app/appTypes";

export default {
    name: "InfoModal",
    data() {
        return {};
    },
    methods: {
        ...mapActions({
            clearInfo: appTypes.ACTION_CLEAR_INFO
        }),
        onCloseHandler() {
            if (this.getInfo.onCloseHandler) {
                this.getInfo.onCloseHandler();
            }
            this.clearInfo();
        }
    },
    computed: {
        ...mapGetters({
            showModalInfo: appTypes.GETTER_SHOW_MODAL_INFO,
            getInfo: appTypes.GETTER_INFO,
        })
    }
};
</script>

<style scoped>
.modal {
    z-index: 9999;
}
</style>
