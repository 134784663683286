import {createI18n} from "vue-i18n";
import {store} from "../store";
import * as appTypes from "../store/app/appTypes";

function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getBrowserLanguage() {
    const userLang = navigator.language || navigator.userLanguage;
    let language = "en";
    
    if (userLang === "pt-BR") language = "pt";
    else if (userLang === "pt-PT") language = "pt";
    else language = "en";
    
    store.dispatch(appTypes.ACTION_SET_LANGUAGE, language);
    return language;
}


export default createI18n({
    locale: getBrowserLanguage(), //en or pt
    fallbackLocale: "en",
    messages: loadLocaleMessages()
});
