<template>
	<d-button :active="active"
	          :block-level="blockLevel"
	          :disabled="disabled"
	          :outline="outline"
	          :pill="pill"
	          :size="this.size"
	          :squared="squared"
	          :theme="theme"
	          @click="handleClick">
		<svg class="mr-3" height="18px" viewBox="0 0 512 512" width="18px" xmlns="http://www.w3.org/2000/svg">
			<path d="m483.738281 0h-455.5c-15.597656.0078125-28.24218725 12.660156-28.238281 28.261719v455.5c.0078125 15.597656 12.660156 28.242187 28.261719 28.238281h455.476562c15.605469.003906 28.257813-12.644531 28.261719-28.25 0-.003906 0-.007812 0-.011719v-455.5c-.007812-15.597656-12.660156-28.24218725-28.261719-28.238281zm0 0"
			      fill="#4267b2"/>
			<path d="m353.5 512v-198h66.75l10-77.5h-76.75v-49.359375c0-22.386719 6.214844-37.640625 38.316406-37.640625h40.683594v-69.128906c-7.078125-.941406-31.363281-3.046875-59.621094-3.046875-59 0-99.378906 36-99.378906 102.140625v57.035156h-66.5v77.5h66.5v198zm0 0"
			      fill="#fff"/>
		</svg>
		<span class="align-middle"><slot></slot></span>
	</d-button>
</template>

<script>
    
    export default {
        name: "GoogleIcon",
        props: {
            theme: {
                type: String
            },
            
            outline: {
                type: Boolean,
                default: false
            },
            
            pill: {
                type: Boolean,
                default: false
            },
            
            squared: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: null
            },
            blockLevel: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            /**
             * Triggered when the button is clicked.
             *
             * @event click
             */
            handleClick(e) {
                this.$emit("click", e);
            }
        }
    };
</script>

<style scoped>

</style>