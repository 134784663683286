<template>
    <d-container class="main-content-container px-md-4 px-sm-1" fluid>

        <!-- Content -->
        <d-row class="mb-4">
            <d-col lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="2" offset-xl="4" sm="8" xl="4">
                <div class="d-block my-5">
                    <img alt="Searchbar.org" class="w-50 d-block mx-auto" id="main-logo"
                         src="@/assets/images/logo.svg">
                </div>
                <d-card class="my-5">
                    <d-card-body class="px-4">
                        <h5 class="text-center" theme="primary">{{ $t("views.signup.signup_title") }}</h5>

                        <d-row>
                            <d-col class=""><!--class pr-1-->
                                <sb-google-btn @click="doLoginWithGoogle" block-level outline theme="light">
                                    {{ $t("views.signin.with_google_label") }}
                                </sb-google-btn>
                            </d-col>
<!--                            <d-col class="pl-1">-->
<!--                                <sb-facebook-btn @click="doLoginWithFacebook" block-level outline theme="light">-->
<!--                                    {{ $t("views.signin.with_facebook_label") }}-->
<!--                                </sb-facebook-btn>-->
<!--                            </d-col>-->
                        </d-row>


                        <span class="d-block text-center my-3">{{ $t("views.signin.or_label") }}</span>

                        <d-form>
                            <div class="form-group">
                                <d-form-input :placeholder="$t('views.signin.email_placeholder')"
                                              :state="form.email.isValid"
                                              @change="checkEmail"
                                              class="mb-2 mr-sm-2 mb-sm-0"
                                              id="f2_Email"
                                              required
                                              size="lg" v-model.trim="form.email.value"/>
                                <d-form-invalid-feedback v-if="form.email.value">
                                    {{ $t("views.signin.invalid_email_msg") }}
                                </d-form-invalid-feedback>
                                <d-form-valid-feedback v-if="form.email.value">{{ $t("views.signin.valid_email_msg") }}
                                </d-form-valid-feedback>
                                <small class="form-text text-muted">{{ $t("views.signin.secure_email_msg") }}</small>
                            </div>

                            <div class="form-group">
                                <d-form-input :placeholder="$t('views.signin.password_placeholder')"
                                              :state="form.password.isValid"
                                              @input="checkPassword"
                                              id="f2_PasswordInput"
                                              required
                                              size="lg"
                                              type="password"
                                              v-model="form.password.value"
                                ></d-form-input>
                                <d-form-invalid-feedback>{{ $t("views.signup.invalid_passwor_msg") }}
                                </d-form-invalid-feedback>
                            </div>

                            <d-button @click.prevent="doSignUp"
                                      :disabled="!isFormValid"
                                      class="d-block mx-auto h-50 mt-5"
                                      size="lg"
                                      type="submit">
                                {{ $t("views.signup.create_button_label") }}
                            </d-button>
                        </d-form>
                    </d-card-body>
                </d-card>
                <div class="d-block my-5">
                    <p class="text-center">{{ $t("views.signup.member_msg") }}
                        <router-link to="/signin"><a><span class="ml-1">{{ $t("views.signup.login") }}</span></a>
                        </router-link>
                    </p>
                </div>
            </d-col>
        </d-row>

    </d-container>


</template>

<script>
import GoogleButton from "../components/shared/buttons/GoogleButton";
import FacebookButton from "../components/shared/buttons/FacebookButton";
import {formValidationMixin} from "../helpers/mixins/formValidationMixins";
import properties from "../data/properties";
import {mapActions} from "vuex";
import * as accountTypes from "../store/account/accountTypes";

export default {
    data() {
        return {
            form: {
                email: {
                    value: "",
                    isValid: null
                },
                password: {
                    value: "",
                    isValid: null
                }
            }
        };
    },
    components: {
        "sb-google-btn": GoogleButton,
        "sb-facebook-btn": FacebookButton
    },
    methods: {
        doSignUp(e) {
            e.target.blur();

            if (this.isFormValid) {
                this.signUpAction(
                    {
                        email: this.form.email.value,
                        password: this.form.password.value
                    });
            }
        },
        doLoginWithGoogle() {
            const host = window.location.origin;
            window.location = properties.GOOGLE_OAUTH_URL + "?redirectTo=" + host + "/oauth";
        },
        doLoginWithFacebook() {
            const host = window.location.origin;
            window.location = properties.FACEBOOK_OAUTH_URL + "?redirectTo=" + host + "/oauth";
        },
        checkEmail(email) {
            this.form.email.isValid = this.validateEmail(email);
        },
        checkPassword(password) {
            this.form.password.isValid = this.validatePassword(password);
            console.log(this.form.password);
        },
        ...mapActions({
            signUpAction: accountTypes.ACTION_SIGNUP
        })
    },
    computed: {
        isFormValid() {
            return this.form.password.isValid && this.form.email.isValid;
        }
    },
    mixins: [formValidationMixin]
};
</script>



