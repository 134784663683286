<template>
  <nav class="nav">
    <a href="#" @click="handleClick()" class="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none text-center">
      <i class="material-icons">&#xE5D2;</i>
    </a>
  </nav>
</template>

<script>
export default {
  name: 'navbar-toggle',
  methods: {
    handleClick() {
      this.$emit('toggle');
    },
  },
  emits: ['toggle']
};
</script>
