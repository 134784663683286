<template>
    <aside :class="['main-sidebar', 'col-12', 'col-md-3', 'col-lg-2', 'px-0', sidebarVisible ? 'open' : '']">
        <div class="main-navbar">
            <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
                <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
                    <div class="w-100">
                        <img alt="Searchbar.org" class="d-block align-top m-auto h-100" id="main-logo"
                             src="@/assets/images/logo.svg">
                    </div>
                </a>
                <a @click="handleToggleSidebar()" class="toggle-sidebar d-sm-inline d-md-none d-lg-none">
                    <i class="material-icons">&#xE5C4;</i>
                </a>
            </nav>
        </div>

        <form action="#" class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
            <div class="input-group input-group-seamless ml-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fas fa-search"></i>
                    </div>
                </div>
                <input aria-label="Search" class="navbar-search form-control" placeholder="Search for something..."
                       type="text">
            </div>
        </form>

        <div class="nav-wrapper">
            <d-nav class="flex-column">
                <li :key="navItemIdx" class="nav-item dropdown" v-for="(item, navItemIdx) in items">
                    <template v-if="item.show">
                        <d-link :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']"
                                :to="item.to" v-d-toggle="`snc-${navItemIdx}`" @click="handleToggleSidebar">
                            <div class="item-icon-wrapper" v-html="item.htmlBefore" v-if="item.htmlBefore"/>
                            <span v-if="item.titleI18nKey">{{ $t(item.titleI18nKey) }}</span>
                            <div class="item-icon-wrapper" v-html="item.htmlAfter" v-if="item.htmlAfter"/>
                        </d-link>
                        <d-collapse :id="`snc-${navItemIdx}`" accordion="sidebar-items-accordion"
                                    class="dropdown-menu dropdown-menu-small" v-if="item.items && item.items.length">
                            <d-dropdown-item :href="subItem.href" :key="subItemIdx"
                                             :to="subItem.to" v-for="(subItem, subItemIdx) in item.items">
                                {{ subItem.title }}
                            </d-dropdown-item>
                        </d-collapse>
                    </template>
                </li>
            </d-nav>
        </div>
    </aside>
</template>

<script>
export default {
    name: "main-sidebar",
    props: {
        /**
         * Whether to hide the logo text, or not.
         */
        hideLogoText: {
            type: Boolean,
            default: false,
        },
        /**
         * The menu items.
         */
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            sidebarVisible: false,
        };
    },
    created() {
        // this.$eventHub.$on("toggle-sidebar", this.handleToggleSidebar);
    },
    beforeDestroy() {
        // this.$eventHub.$off("toggle-sidebar");
    },
    methods: {
        handleToggleSidebar() {
            this.sidebarVisible = !this.sidebarVisible;
        },
    },
    expose: ["handleToggleSidebar"]
};
</script>

<style lang="scss" scoped>
.main-sidebar {
    .item-icon-wrapper {
        display: inline-block;
    }

    .dropdown-menu {
        display: block;
    }
}
</style>
