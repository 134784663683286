const prefix = 'page/';

// Getters
export const GETTER_TOTAL_PAGES = prefix + 'GETTER_TOTAL_PAGES';
export const GETTER_PROCESSED_PAGES = prefix + 'GETTER_PROCESSED_PAGES';
export const GETTER_UNPROCESSED_PAGES = prefix + 'GETTER_UNPROCESSED_PAGES';
export const GETTER_ERROR_PAGES = prefix + 'GETTER_ERROR_PAGES';
export const GETTER_PAGE_TO_EDIT = prefix + 'GETTER_PAGE_TO_EDIT';
export const GETTER_CUSTOM_PAGES = prefix + 'GETTER_CUSTOM_PAGES';
export const GETTER_CUSTOM_PAGES_FILTER = prefix + 'GETTER_CUSTOM_PAGES_FILTER';
export const GETTER_CUSTOM_PAGES_QUERY = prefix + 'GETTER_CUSTOM_PAGES_QUERY';
export const GETTER_CUSTOM_PAGES_PAGINATION = prefix + 'GETTER_CUSTOM_PAGES_PAGINATION';
export const GETTER_CUSTOM_PAGES_ORDENATION = prefix + 'GETTER_CUSTOM_PAGES_ORDENATION';

// Mutations
export const MUTATE_SET_TOTAL_PAGES = prefix + 'MUTATE_SET_TOTAL_PAGES';
export const MUTATE_SET_PROCESSED_PAGES = prefix + 'MUTATE_SET_PROCESSED_PAGES';
export const MUTATE_SET_UNPROCESSED_PAGES = prefix + 'MUTATE_SET_UNPROCESSED_PAGES';
export const MUTATE_SET_ERROR_PAGES = prefix + 'MUTATE_SET_ERROR_PAGES';
export const MUTATE_SET_PAGE_TO_EDIT = prefix + 'MUTATE_SET_PAGE_TO_EDIT';
export const MUTATE_SET_CUSTOM_PAGES = prefix + 'MUTATE_SET_CUSTOM_PAGES';
export const MUTATE_SET_CUSTOM_PAGES_FILTER = prefix + 'MUTATE_SET_CUSTOM_PAGES_FILTER';
export const MUTATE_SET_CUSTOM_PAGES_QUERY = prefix + 'MUTATE_SET_CUSTOM_PAGES_QUERY';
export const MUTATE_SET_CUSTOM_PAGES_PAGINATION = prefix + 'MUTATE_SET_CUSTOM_PAGES_PAGINATION';
export const MUTATE_SET_CUSTOM_PAGES_ORDENATION = prefix + 'MUTATE_SET_CUSTOM_PAGES_ORDENATION';

// Actions
export const ACTION_GET_PAGES_STATUS = prefix + 'ACTION_GET_PAGES_STATUS';
export const ACTION_GET_CUSTOM_PAGES = prefix + 'ACTION_GET_CUSTOM_PAGES';
export const ACTION_POST_CUSTOM_PAGE = prefix + 'ACTION_POST_CUSTOM_PAGE';
export const ACTION_PUT_CUSTOM_PAGE = prefix + 'ACTION_PUT_CUSTOM_PAGE';
export const ACTION_DELETE_CUSTOM_PAGE = prefix + 'ACTION_DELETE_CUSTOM_PAGE';