<template>
    <div v-if="contentModal.show" class="back">
        <div class="inner-modal align-self-center align-middle pl-2 py-3" v-sb-click-outside="onClose">
            <div class="close" @click="onClose"><i class="icon-ic_close-1"></i></div>
            <component
                :is="contentModal.content"
                :props="contentModal.props"
                @close="onClose"></component>
        </div>
    </div>
</template>

<script>
import InfiniteProgressBar from "../../../components/shared/InfiniteProgressBar";
import {mapGetters, mapActions} from "vuex";
import * as appTypes from "../../../store/app/appTypes";
import ClickOutsideDirective from "../../../helpers/directives/ClickOutsideDirective";

export default {
    name: "ContentModal",
    data() {
        return {};
    },
    components: {
        "sb-infinite-progress": InfiniteProgressBar
    },
    methods: {
        ...mapActions({
            closeModal: appTypes.ACTION_CLEAR_CONTENT_MODAL
        }),
        onClose() {
          if (this.contentModal.onClose) {
            this.contentModal.onClose();
          }
          this.closeModal();
        }
    },
    computed: {
        ...mapGetters({
            contentModal: appTypes.GETTER_CONTENT_MODAL
        })
    },
    directives: {
        SbClickOutside: ClickOutsideDirective
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.contentModal.show) {
                //close modal
                this.closeModal();

                //avoid router redirect
                next(false);
            } else next();
        });

        //watch destroyed programmatic hook and regist custom routeGuard
        // this.$once("hook:destroyed", () => {
        //     unregisterRouterGuard();
        // });
    },
};
</script>

<style lang="scss" scoped>
@import "src/scss/overrides";

.back {
    background-color: rgba(0, 0, 0, .7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    display: flex;

    .inner-modal {
        max-width: 940px;
        background-color: white;
        max-height: 85vh;
        display: block;
        margin: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
        border-radius: 12px;

        .close {
            position: sticky;
            color: #3e3e3e;
            cursor: pointer;
            padding: 2px;
            font-weight: bold;
            top: 0;
            z-index: 10;
        }
    }
}

@media only screen and (max-width: $media_break_point2) {
    .back {
        .inner-modal {
            max-height: 100vh;
        }
    }
}
</style>
