import Vue from 'vue';
import {createStore, createLogger} from 'vuex';
import accountStore from "../store/account/accountStore";
import appStore from "../store/app/appStore";
import pageStore from "../store/page/pageStore";
import loaderStore from "../store/loader/loaderStore";
import paymentStore from "../store/payment/paymentStore";
import analyticsStore from "../store/analytics/analyticsStore";

const showDSVTools = process.env.VUE_APP_SHOW_DSV_TOOLS === "true";
const plugins = [];

if(showDSVTools) plugins.push(createLogger());

export const store = createStore({
    modules: {
        accountStore,
        appStore,
        pageStore,
        loaderStore,
        paymentStore,
        analyticsStore
    },
    plugins: plugins
});
