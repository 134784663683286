import Axios from "axios";
import properties from "../properties";
import {store} from "../../store";
import * as accountTypes from "../../store/account/accountTypes";
import * as appTypes from "../../store/app/appTypes";

Axios.defaults.baseURL = properties.API_URL;
Axios.interceptors.request.use(
    (config) => {
        let token = store.getters[accountTypes.GETTER_TOKEN];
        
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        
        return config;
    },
    
    (error) => {
        return Promise.reject(error);
    }
);

export const accountResources = {
    postRegister(email, password) {
        return Axios.post("/account/register", {email, password});
    },
    postLogin(email, password) {
        return Axios.post("/account/login", {email, password});
    },
    getAccount() {
        return Axios.get("/account/");
    },
    getSites() {
        return Axios.get("/account/site");
    },
    putSite(site) {
        return Axios.put("/account/site", {site});
    },
    putInfo(info) {
        return Axios.put("/account/info", info);
    },
    postRecovery(email) {
        return Axios.post("/account/recovery", {email});
    },
    putPassword(password, token) {
        let config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        };
        return Axios.put("/account/password", {password}, config);
    },
    getSettings() {
        return Axios.get("/account/settings");
    },
    putSettings(settings) {
        return Axios.put("/account/settings", settings);
    }
};
export const jobResources = {
    getJob(jobId) {
        return Axios.get("/job/" + jobId);
    },
    getRunningJobs() {
        return Axios.get("/job");
    }
};
export const pageResources = {
    getStatus() {
        return Axios.get("/page/metadata/status");
    },
    getCustomPages(params) {
        return Axios.get("/page/raw", {params});
    },
    postPages(payload) {
        return Axios.post("/page", payload);
    },
    putPages(payload) {
        return Axios.put("/page", payload);
    },
    deletePage(payload) {
        return Axios.delete("/page", {data: payload});
    }
};
export const loaderResources = {
    getLoader() {
        return Axios.get("/loader");
    }
};
export const paymentResources = {
    getSubsciption() {
        return Axios.get("/payment/subscription");
    },
    
    getPlans(language) {
        let config = {
            headers: {
                "language": language
            }
        };
        return Axios.get("/payment/plans", config);
    },
    
    getSession(planId) {
        let language = store.getters[appTypes.GETTER_LANGUAGE];
        let erroPage = "https://www.searchbar.org/en-us/error";
        let successPage = "https://www.searchbar.org/en-us/membership-successfully-adquired";
        
        if (language === "pt") {
            erroPage = "https://www.searchbar.org/pt-br/erro";
            successPage = "https://www.searchbar.org/pt-br/plano-adquirido-com-sucesso";
        }
        
        return Axios.get("/payment/session", {
            params: {
                planId: planId,
                successUrl: successPage,
                cancelUrl: erroPage
            }
        });
    }
};
export const analyticsResources = {
    getAnalytics(payload) {
        return Axios.get("/analytics", {params: payload});
    }
};
